/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const MenuTopBlack = () => {
  const data = useStaticQuery(query)

  return (
    <div className="prop-menu" sx={{ backgroundColor: "chocolate" }}>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-around",
          padding: "26px 0 30px",
          marginBottom: "2px",
          textTransform: "uppercase",
          fontWeight: "500",
          a: {
            color: "#ffffff",
          },
        }}
      >
        {data.menu._rawChildren.map((menuItem, index) => {
          return (
            <div key={index}>
              <Link
                to={"/" + menuPath(menuItem.children.document.slug.current)}
                title={menuItem.children.title}
              >
                {menuItem.children.title}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MenuTopBlack

function menuPath(sanityPath) {
  if (sanityPath === "home") {
    return "#search-results"
  } else {
    return sanityPath
  }
}

const query = graphql`
  query MenuQuery2 {
    menu: sanityMenu {
      title
      _rawChildren(resolveReferences: { maxDepth: 10 })
    }
  }
`
