/** @jsx jsx */
import { jsx } from "theme-ui"
import axios from "axios"
import { useState } from "react"
import { Helmet } from "react-helmet"

const MyForm = ({ title, contacts }) => {
  let contact = "Anthony"

  if (contacts?.teamFirstName) {
    contact = contacts.teamFirstName
  }

  if (Array.isArray(contacts) && contacts.length > 0) {
    contact = contacts[0].teamFirstName
  }

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      success: false,
    })
    if (ok) {
      setServerState({
        success: true,
      })
      if (typeof window !== "undefined") {
        // window.location.href = "/contact-thank-you"
      }
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (e.target[5].value === "") {
      setServerState({ submitting: true })
      axios({
        method: "post",
        url: "https://getform.io/f/a5fe3de9-06d5-472c-bbe8-f8ff7099a73d",
        data: new FormData(form),
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form)
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form)
        })
    } else {
      console.log("failed")
    }
  }

  return (
    <div>
      <div>
        {!serverState.success ? (
          <div>
            <div
              sx={{
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              Fill out the form below to connect with
              <strong> {contact} </strong> about <i>{title}</i>.
            </div>
            <form
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",

                input: {
                  border: "1px solid #484242",
                  color: "#484242",
                  backgroundColor: "transparent",
                  padding: "8px 20px",
                  fontSize: "1rem",
                  width: "100%",
                  borderRadius: "0px !important",
                  boxSizing: "border-box",
                  "&::placeholder": {
                    color: "#484242",
                  },
                  "&:focus-visible": {
                    borderRadius: "0px !important",
                    outline: "none !important",
                  },
                },
                button: {
                  border: "1px solid #484242",
                  color: "#484242",
                  backgroundColor: "transparent",
                  padding: "8px 30px",
                  fontSize: "1rem",
                  cursor: "pointer",
                  display: "inline-block",
                  marginTop: "20px",
                  marginBottom: "20px",
                  borderRadius: "18px",
                  "&:hover": {
                    backgroundColor: "#EBE6E0",
                  },
                },

                ".label-input": {
                  marginBottom: "15px",
                  width: "100%",
                },
              }}
              onSubmit={handleOnSubmit}
            >
              <div className="label-input">
                <label for="name">Name*</label>
                <input type="text" required name="name" />
              </div>
              <div className="label-input">
                <label for="email">Email*</label>
                <input type="email" required name="email" />
              </div>

              <div className="label-input">
                <label for="phone">Phone</label>
                <input type="phone" name="phone" />
              </div>

              <div
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>How can we help you?</div>
                <textarea
                  sx={{
                    height: "150px",
                    padding: "8px 20px",
                    fontSize: "1rem",
                    fontFamily: "helvetica",
                    color: "#484242",
                  }}
                  required
                  name="how_help"
                />
              </div>
              <input type="hidden" name="property" value={title} />
              <div
                sx={{
                  display: "none",
                }}
              >
                <input type="hidden" name="_gotcha" />
              </div>
              <button type="submit">Send</button>
            </form>
          </div>
        ) : (
          <div
            sx={{
              marginTop: "20px",
            }}
          >
            Thank you for connecting with us. We will be in touch with you soon.
          </div>
        )}
      </div>
    </div>
  )
}

export default MyForm
