/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Container from "../container"

const ConditionalLayoutHeader = () => {
  return (
    <header
      sx={{
        display: ["block", "none"],
        zIndex: "11",
        width: "100%",
        backgroundImage:
          "linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.6))",
        paddingBottom: "0px",
      }}
    >
      <Container fullWidth noMobilePadding={true}>
        <div
          sx={{
            backgroundColor: "rgba(249, 248, 246, 0.88);",
            padding: "14px 0px 10px",
          }}
        >
          <Container>
            <div
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                sx={{
                  marginRight: "40px",
                }}
              >
                Anthony Simpson, Broker
              </div>
              <div>830-955-1725</div>
            </div>
          </Container>
        </div>
      </Container>
    </header>
  )
}

export default ConditionalLayoutHeader
