/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

import BlockContent from "@sanity/block-content-to-react"
import Serializers from "../../components/serializers/serializers"
import PropImages from "../../components/propImages"
import ContactForm from "../../components/contactForm"
import Logo from "../../components/Logo"
import MapPin from "../../components/atoms/MapPin"
import { GatsbyImage } from "gatsby-plugin-image"
import PropertyForm from "../../components/propertyForm"
import MenuTopBlack from "../../components/menuTopBlack"

const slugify = require("slugify")

const Ribbon = ({ color, label }) => (
  <div
    sx={{
      color: "#ffffff",
      fontSize: "1rem",
      position: "absolute",
      zIndex: "1",
    }}
  >
    <div
      sx={{
        clipPath: "polygon(93% 23%, 100% 50%, 93% 77%, 0 77%, 5% 50%, 0 23%)",
        backgroundColor: color,
        // width: "190px",
        padding: "0px 20px",
        minWidth: "60px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {label}
    </div>
  </div>
)

const PropertyContainer = ({
  images,
  openModal,
  facebookPublicURL,
  twitterPublicURL,
  carrotLeftPublicURL,
  carrotDownPublicURL,
  contacts,
  node,
  title,
  county,
  acreage,
  description,
  propertyInfo,
  propPath,
  status,
  videoId,
  logo,
  OriginatingSystemName,
  ListOfficeName,
}) => {
  let price = node.price
  if (typeof node.price === "string") {
    price = parseFloat(node.price)
  }
  // let acreage = property.acreage
  if (acreage) {
    if (acreage > 0) {
      acreage =
        acreage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " acres"
    } else {
      acreage = " "
    }
  } else {
    acreage = " "
  }
  return (
    <div
      className="prop-container"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        height: ["auto", "100%", "100%"],

        justifyContent: "space-betweeen",
        flexDirection: ["column-reverse", "row", "row"],
        a: {
          color: "#18459A",
          textDecoration: "underline",
        },
      }}
    >
      <div
        className="prop-left"
        sx={{
          width: ["100%", "40%", "40%"],
          overflowY: "auto",
          height: ["auto", "100%", "100%"],
          color: "davisGray",
          boxSizing: "border-box",
          backgroundColor: "white",
        }}
      >
        {logo && (
          <div
            sx={{
              display: ["none", "block"],
            }}
          >
            <Link to="/">
              <Logo></Logo>
            </Link>
          </div>
        )}
        <div
          sx={{
            padding: [
              "20px 20px",
              "95px 30px 20px 30px",
              "95px 30px 20px 30px",
            ],
            paddingTop: logo ? "0px !important" : "10px",
            backgroundColor: "white",
            borderLeft: "1px solid #E5E5E5",
            borderRight: "1px solid #E5E5E5",
          }}
        >
          <div
          sx={{
            display:"flex",
            flexDirection:["column-reverse","column","column"],
          }}>
          <div>
          <div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "30px",
              marginTop:["20px","0px","0px"],
            }}
          >
            <div
              className="share-button"
              sx={{
                fontSize: "16px",
                marginRight: "23px",
                color: "#5C5757",
              }}
            >
              Share this Listing
            </div>
            <div
              sx={{
                lineHeight: "0px",
                a: {
                  display: "inline-block",
                  marginRight: "5px",
                  "&:last-child": {
                    marginRight: "0px",
                  },
                },
              }}
            >
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${propPath}`}
                target="_blank"
                rel="noopener"
                title="Facebook"
                sx={{ fontSize: "0px" }}
              >
                <img
                  sx={{
                    width: "27px",
                  }}
                  src={facebookPublicURL}
                  alt="Facebook"
                  title="Facebook"
                />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${propPath}`}
                target="_blank"
                rel="noopener"
                title="Twitter"
                sx={{
                  marginLeft: "5px",
                  fontSize: "0px",
                }}
              >
                <img
                  sx={{
                    width: "27px",
                  }}
                  src={twitterPublicURL}
                  alt="Twitter"
                  title="Twitter"
                />
              </a>
            </div>
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "column", "row", "row"],
              justifyContent: "space-between",
              alignItems: ["flex-start"],
              paddingBottom: "20px",
            }}
          >
            <div>
              <div
                sx={{
                  color: "#5C5757",
                }}
              >
                For more information about this property contact:
              </div>
              <div
                sx={{
                  borderLeft: "4px solid #C4C4C4",
                  paddingLeft: "12px",
                  marginTop: "10px",
                  a: {
                    color: "#18459A",
                    textDecoration: "underline",
                  },
                }}
              >
                {contacts && contacts.length > 0 ? (
                  contacts.map((contact, index) => (
                    <div
                      index={index}
                      sx={{
                        marginBottom: "10px",
                        "&:last-child": {
                          marginBottom: "0px",
                        },
                      }}
                    >
                      <div>
                        {contact.teamFirstName}
                        {" " + contact.teamLastName}
                      </div>
                      <div>
                        <a href={"tel:" + contact.teamCellNumber}>
                          {contact.teamCellNumber}
                        </a>
                      </div>
                      <div>
                        <a href={"mailto:" + contact.teamEmail}>
                          {contact.teamEmail}
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <div>Anthony Simpson</div>
                    <div>
                      <a href="tel:210-854-6365">210-854-6365</a>
                    </div>
                    <div>
                      <a href="mailto:anthony@simpsonranches.com">
                        anthony@simpsonranches.com
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          </div>
          <div>
          <div
            sx={{
              position: "relative",
              height: "40px",
            }}
          >
            {status === "reduced" || status === "Reduced" ? (
              <Ribbon color="#189A6D" label="Reduced" />
            ) : status === "under-contract" ||
              status === "Active Under Contract" ? (
              <Ribbon color="#B5941F" label="Under Contract" />
            ) : status === "for-sale" || status === "Active" ? (
              <Ribbon color="#18459A" label="For Sale" />
            ) : status === "buyer-represented" ? (
              <Ribbon color="#4F55A1" label="Buyer Represented" />
            ) : status === "Pending" ? (
              <Ribbon color="#189a6d" label="Pending" />
            ) : status === "just-sold" ? (
              <Ribbon color="#B5701F" label="Just Sold" />
            ) : status === "sold" ? (
              <Ribbon color="#9B3708" label="Sold" />
            ) : (
              <div></div>
            )}
          </div>
          {node.realStatus && (
            <div
              sx={{
                marginTop: "5px",
              }}
            >
              {node.realStatus}
            </div>
          )}
          <div
            sx={{
              marginTop: "20px",
            }}
          >
            {title && (
              <h1
                sx={{
                  fontSize: "24px !important",
                  lineHeight: "1.2",
                  fontFamily: "Inter, sans-serif !important",
                  textTransform: "uppercase",
                  margin: "0px",
                  fontWeight: "500 !important",
                  borderBottom: "1px solid",
                  borderColor: "davisGray",
                  display: "block",
                  width: "100%",
                  paddingBottom: "17px",
                }}
              >
                {title}
              </h1>
            )}
            <div
              sx={{
                borderBottom: "1px solid",
                borderColor: "davisGray",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "20px 0px",
                color: "davisGray",
              }}
            >
              {acreage && (
                <div
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "400",
                  }}
                >
                  {acreage}
                </div>
              )}
              {county && (
                <a
                  sx={{
                    fontSize: "1rem",
                    color: "davisGray",
                    fontWeight: "400",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  href={`/${slugify(
                    county.toLowerCase()
                  )}-county-ranches-for-sale`}
                  title={`${county} county ranches for sale`}
                  rel="noreferrer"
                >
                  <MapPin /> {county} County
                </a>
              )}
            </div>
            {node.status !== "sold" && node.price && (
              <div
                sx={{
                  fontSize: "36px",
                  fontWeight: "700",
                  lineHeight: "1.2",
                  padding: "10px 0px",
                  textAlign: "right",
                }}
              >
                {price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </div>
            )}
          </div>
          {node.status !== "sold" && videoId && (
            <div
              sx={{
                margin: "10px 0 20px",
                position: "relative",
                paddingBottom: "56.25%",
                height: "0",
                overflow: "hidden",
              }}
            >
              <iframe
                sx={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                src={"https://www.youtube.com/embed/" + videoId}
                title="YouTube video player"
                frameborder="0"
                allow="fullscreen"
              ></iframe>
            </div>
          )}
          <Accordion
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
            preExpanded={["0", "13"]}
            sx={{
              ".accordion__button": {
                backgroundImage: `url(${carrotLeftPublicURL})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "calc(100% - 10px)",
                backgroundPositionY: "center",
                backgroundSize: "8px",
                cursor: "pointer",
                "& > h2": {
                  fontSize: "16px",
                  fontFamily: "Inter, sans-serif !important",
                  fontWeight: 700,
                  lineHeight: "22px",
                  margin: 0,
                  textTransform: "uppercase",
                },
              },
              ".accordion__button[aria-expanded='true']": {
                backgroundImage: `url(${carrotDownPublicURL})`,
                backgroundSize: "15px",
                backgroundPositionX: "calc(100% - 5px)",
              },
              ".accordion__item": {
                borderBottom: "thin solid",
                borderColor: "davisGray",
                paddingBottom: "20px",
                paddingTop: "20px",
              },
              ".accordion__heading": {
                fontSize: "1.125rem",
                color: "davisGray",
                fontWeight: "700",
              },
              ".accordion__panel": {
                marginTop: "10px",
                lineHeight: "1.438rem",
              },
            }}
          >
            {description && (
              <AccordionItem index={0} uuid={"0"}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h2>Description</h2>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div>{description}</div>
                </AccordionItemPanel>
              </AccordionItem>
            )}
            {node._rawPropertyInfo &&
              node._rawPropertyInfo.map((info, index) => (
                <AccordionItem uuid={index.toString()} index={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h2>{info.propertyInfoLabel}</h2>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <BlockContent
                      blocks={info.propertyInfo}
                      serializers={Serializers}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
              {node.status !== "sold" && node._rawPropertyFiles && (
              <AccordionItem uuid={"12"} index={12}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                FILES
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <ul>
                  {node._rawPropertyFiles.map((file, index) => (
                    file.file && file.fileoLabel &&(
                      <li key={index}>
                      {console.log(file)}
                    <a href={file.file.asset.url} target="_blank">{file.fileoLabel}</a>                        
                    </li>
                    )

                  ))}
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            )}
            {node.status !== "sold" && node.propertyInteractiveLocationMap && (
              <AccordionItem uuid={"13"} index={13}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h2>MAP</h2>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    Click “View Full Screen” button on map to see the map legend
                    and additional features.
                  </div>
                  <div
                    key={`map`}
                    id="___map"
                    dangerouslySetInnerHTML={{
                      __html: node.propertyInteractiveLocationMap,
                    }}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            )}
          </Accordion>
          </div>
          </div>
          <PropertyForm title={title} contacts={contacts}></PropertyForm>
          
          {ListOfficeName && OriginatingSystemName && (
            <div
            sx={{
              marginBottom:"30px",
            }}
            >
              <div>
                <span>Listed By: </span>
                <span>{ListOfficeName}</span>
              </div>
              <div
                sx={{
                  marginTop: "10px",
                }}
              >
                © {new Date().getFullYear() + " " + OriginatingSystemName}. All
                rights reserved. Information Deemed Reliable but Not Guaranteed.
                Information on this site is provided exclusively for consumers
                personal, non-commercial use and may not be used for any purpose
                other than to identify prospective properties consumers may be
                interested in purchasing. The offer is made only to Participants
                of the MLS where the listing is filed.
              </div>
            </div>
          )}
          
          
          
          
          <div 
          id="modal-disclaimer"
          sx={{
            // border:"thin solid",
            // borderColor:"davisGray",
            // padding:"0px 10px",
            p:{
              fontSize:"12px",

            }
          }}>
            <p>
            All information provided is deemed reliable, but is not guaranteed and should be independently verified.
            </p>
            <p>
            Real Estate buyers are hereby notified that real properties and its rights and amenities in the State of Texas are subject to many forces and impacts whether natural, those caused by man, or otherwise; including, but not limited to, drought or other weather related events, disease (e.g. Oak Wilt or Anthrax), invasive species, illegal trespassing, previous owner actions, neighbor actions and government actions. Prospective buyers of Texas real estate should investigate any concerns regarding a specific real property to their satisfaction.
            </p>
            <p>
            Buyer’s brokers must be identified on first contact, and must accompany buying prospect on first showing to be allowed full fee participation. If this condition is not met, fee participation will be at the sole discretion of Simpson Ranches & Land, LLC, Broker.
            </p>
            <p>
            Simpson Ranches & Land, LLC reserves the right to require any or all interested buyer(s) of a particular property to provide proof of financial ability to purchase said property prior to the initial showing or any subsequent showing of the property. Simpson Ranches & Land, LLC also reserves the right to refuse to show a property to a potential buyer for any reason at Simpson Ranches & Land, LLC’s sole and absolute discretion.
            </p>
          </div>
        </div>
      </div>

      <div
        className="prop-right"
        sx={{
          width: ["100%", "60%", "60%"],
          backgroundColor: "white",
          overflowY: ["hidden", "auto", "auto"],
          height: ["auto", "100%", "100%"],
          paddingRight: ["0px", "10px", "10px"],
          boxSizing: "border-box",
          display: ["block", "block", "flex"],
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignContent: "start",
          padding: "5px",
          img: {
            borderRadius: "5px",
          },
          "> div": {
            paddingTop: "75px",
            width: "calc(50% - 2.5px)",
            marginBottom: "5px",
            "&:nth-of-type(1)": {
              width: "100%",
            },
            ".yt-lite": {
              height: "100%",
              pointerEvents: "none",
            },
          },
        }}
      >
        {node.status != "sold" ? (
          <PropImages newImages={images} openModal={openModal}></PropImages>
        ) : (
          node.propertyFeaturedImage && (
            <div>
              <MenuTopBlack />
              <div
                sx={{
                  display: ["flex", "none", "none"],
                  justifyContent: "center",
                }}
              >
                <div
                  className="prop-menu"
                  sx={{ background: "white", maxWidth: "326px", width: "100%" }}
                >
                  <Link to="/">
                    <Logo />
                  </Link>
                </div>
              </div>
              <GatsbyImage
                sx={{
                  maxWidth: "100%",
                  height: "100%",
                  maxHeight: "100%",
                }}
                image={node.propertyFeaturedImage.asset.gatsbyImageData}
                height={600}
                aspectRatio={4 / 2}
                alt="Gallery Image"
                title="Gallery Image"
              />
            </div>
          )
        )}
      </div>
    </div>
  )
}
export default PropertyContainer
