import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const MapPin = () => {
  const data = useStaticQuery(query)
  return (
    <GatsbyImage
      sx={{
        width: "11px",
        height: "15px",
        img: {
          objectFit: "unset !important",
        },
      }}
      image={data.mappin.childImageSharp.gatsbyImageData}
    />
  )
}

export default MapPin

const query = graphql`
  query MapPinQuery {
    mappin: file(name: { eq: "map-pin" }) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
