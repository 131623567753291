/** @jsx jsx */
import { jsx } from "theme-ui"
import { useMemo } from "react"
import Layout from "../../layout"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-4"
import Container from "../container"
import Header from "./Header"
import { useEffect, useState } from "react"

const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return children
}

const ConditionalLayout = ({ ...props }) => {
  const search = typeof window !== "undefined" ? window.location.search : ""
  const isModal = useMemo(() => {
    const arr = search.split("=")
    if (arr.length > 1 && arr[1] === "true") {
      return true
    }
    return false
  }, [search])
  return (
    <ClientOnly>
      <ModalRoutingContext.Consumer isModal={true}>
        {({ modal, closeTo }) =>
          modal || isModal ? (
            <div
              id="conditional-layout"
              sx={{
                zIndex: "1",
                position: "relative",
                background: "#f7f7f7",
                height: "100%",
                fontFamily: "body",
                fontWeight: "400",
                fontSize: "2",
                lineHeight: "body",
                letterSpacing: "0px",
                p: {
                  a: {
                    color: "davisGray",
                  },
                },
                ".prop-menu": {
                  display: "none",
                },
              }}
            >
              <Header />
              <div
                sx={{
                  width: ["100%", "100%", "100%"],
                  position: "absolute",
                  zIndex: "9",
                  bg: "chocolate",
                  padding: "20px 20px",
                  boxSizing: "border-box",
                }}
              >
                <Link
                  to={closeTo || "/"}
                  state={{
                    noScroll: true,
                  }}
                  className="prop-modal-close"
                  title="Modal Close"
                  sx={{
                    textDecoration: "none",
                    position: "relative",
                    zIndex: "9",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                    "&:before": {
                      display: ["none", "block"],
                      width: "0",
                      height: "0",
                      content: "''",
                      borderTop: "18px solid transparent",
                      borderBottom: "18px solid transparent",
                      borderRight: "18px solid white",
                      borderRadius: "3px",
                      marginRight: "18px",
                    },
                  }}
                >
                  <svg
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    sx={{
                      transform: "rotate(90deg)",
                      color: "white",
                      height: "30px",
                      display: ["block", "none", "none", "none"],
                    }}
                  >
                    <path
                      stroke="none"
                      d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"
                      sx={{
                        fill: "white",
                      }}
                    ></path>
                  </svg>
                  <span
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      display: "block",
                      position: "relative",
                      top: "1px",
                    }}
                  >
                    Back to Listings
                  </span>
                </Link>
              </div>
              {props.children}
            </div>
          ) : (
            <Layout banner={true} header={false}>
              <Container background={props.background} noMobilePadding={true}>
                <div
                  sx={{
                    height: ["auto", "calc(100vh)", "calc(100vh)"],
                    overFlow: "hidden",
                    ".prop-share": {
                      display: "none",
                    },
                    ".prop-left": {
                      "> div": {
                        paddingTop: "0px",
                      },
                    },
                    ".prop-right": {
                      "> div": {
                        paddingTop: "0px",
                      },
                    },
                  }}
                >
                  {props.children}
                </div>
              </Container>
            </Layout>
          )
        }
      </ModalRoutingContext.Consumer>
    </ClientOnly>
  )
}

export default ConditionalLayout
