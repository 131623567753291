/** @jsx jsx */
import { jsx } from "theme-ui"
import { withBreakpoints } from "gatsby-plugin-breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import _ from "lodash"
import { forwardRef, useEffect, useRef, useMemo } from "react"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import { Fade } from "react-slideshow-image"

import "./fullslide.css"
import "react-slideshow-image/dist/styles.css"

const ReturnImage = forwardRef(({ image }, ref) => {
  if (image?.asset?.gatsbyImageData) {
    return (
      <GatsbyImage
        sx={{
          width: "auto",
          maxHeight: "90vh",
          margin: "auto",
          objectFit: "contain",
        }}
        image={image.asset.gatsbyImageData}
        alt="Slide Image"
        title="Slide Image"
        objectFit="contain"
        width={800}
        aspectRatio={4 / 2}
      />
    )
  } else if (image.video) {
    return (
      <div ref={ref} style={{ maxHeight: "90vh", width: "auto" }}>
        <LiteYouTubeEmbed id={image.video}></LiteYouTubeEmbed>
      </div>
    )
  } else if (image.MediaURL) {
    return (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          img: {
            aspectRatio: "20 / 13",
            objectFit: "cover",
            maxWidth: "100%",
          },
        }}
      >
        <img src={image.MediaURL} />
      </div>
    )
  } else if (image?.childImageSharp) {
    return (
      <GatsbyImage
        sx={{
          width: "auto",
          maxHeight: "90vh",
          margin: "auto",
        }}
        image={image.childImageSharp.gatsbyImageData}
        width={600}
        alt="Slide Image"
        title="Slide Image"
        objectFit="contain"
        aspectRatio={4 / 2}
      />
    )
  }
  else{
    return null;
  }
})
const FullSlide = ({ ...props }) => {
  const ref = useRef()
  const divRef = useRef()
  const videoRef = useRef()
  props.images.forEach((image, index) => {
    if(!image.asset && !image.MediaURL){
      delete props.images[index];
    }    
})
useEffect(() => {
    if (divRef) {
      divRef.current?.focus()
    }
  }, [])

  useEffect(() => {
    const toggleArrows2 = e => {
      if (e.key === "ArrowRight") {
        ref.current.goNext()
      }
      if (e.key === "ArrowLeft") {
        ref.current.goBack()
      }
    }
    window.addEventListener("keydown", toggleArrows2)
    return () => window.removeEventListener("keydown", toggleArrows2)
  }, [])

  const { breakpoints } = props

  const properties = useMemo(() => {
    return {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      arrows: true,
      indicators: true,
      autoplay: false,
      onChange: previous => {
        if (videoRef.current) {
          if (previous === 1) {
            videoRef.current.childNodes.forEach(element => {
              if (element.classList.contains("lyt-activated")) {
                element.childNodes.forEach(elementInner => {
                  if (elementInner.tagName === "IFRAME") {
                    var src = elementInner.src.replace(
                      "autoplay=1",
                      "autoplay=0"
                    )
                    elementInner.src = src
                  }
                })
              }
            })
          }
        }
      },
    }
  }, [videoRef])

  return (
    <div className="slider-container" ref={divRef}>
      {breakpoints.sm ? (
        <div className="custom-slider">
          {props.images.map((image, index) => (
            <div
              className="custom-slide"
              key={_.uniqueId()}
              sx={{
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <ReturnImage image={image} index={index}></ReturnImage>
            </div>
          ))}
        </div>
      ) : (
        <Fade
          {...properties}
          style={{ maxHeight: "90vh" }}
          ref={ref}
          defaultIndex={props.index}
          sx={{
            width: "100%",
          }}
        >
          {props.images.map((image, index) => (
            <ReturnImage
              key={_.uniqueId()}
              image={image}
              index={index}
              ref={videoRef}
            ></ReturnImage>
          ))}
        </Fade>
      )}
    </div>
  )
}

export default withBreakpoints(FullSlide)
