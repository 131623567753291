/** @jsx jsx */
import { jsx } from "theme-ui"
import Modal from "react-modal"
import FullSlide from "../../components/fullslide"

const GalleryModal = ({ open, closeModal, title, images, slideIndex }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      className="slideshow-modal"
      id="property-overlay"
    >
      <div sx={{ paddingTop: ["30px", "0px"] }}>
        <div
          sx={{
            fontFamily: "Inter",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginLeft: "-40px",
            marginTop: "-40px",
            padding: "30px 40px",
            borderBottom: "1.5px solid #6D6465",
          }}
        >
          <h2
            sx={{
              margin: "0px",
              color: "#A29A9A",
              fontSize: "24px",

              fontWeight: 400,
            }}
          >
            Photo Gallery - {title}
          </h2>
          <div
            sx={{
              display: ["none", "block"],
              margin: "0",
              color: "#A29A9A",
              fontSize: "16px",
              fontWeight: 400,
              marginRight: "40px",
            }}
          >
            Close
          </div>
          <div
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: "40px",
              top: "30px",
              zIndex: "9",
              height: "30px",
              width: "30px",
              cursor: "pointer",
              ":after": {
                content: "' '",
                height: "30px",
                borderLeft: "3px solid #A29A9A",
                position: "absolute",
                transform: "rotate(45deg)",
                left: "10px",
              },
              ":before": {
                content: "' '",
                height: "30px",
                borderLeft: "3px solid #A29A9A",
                position: "absolute",
                transform: "rotate(-45deg)",
                left: "10px",
              },
            }}
          />
        </div>

        <FullSlide images={images} index={slideIndex} />
      </div>
    </Modal>
  )
}

export default GalleryModal
