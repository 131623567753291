/** @jsx jsx */
import { jsx } from "theme-ui"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import { Slide } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"
import MenuTopBlack from "./menuTopBlack"
import { Link } from "gatsby"
import Logo from "./Logo"

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  arrows: true,
  indicators: false,
  autoplay: false,
}
const ReturnImage = ({ image, index }) => {
  if (image.asset) {
    return (
      <div
        sx={{
          height: "100%",
        }}
      >
        <GatsbyImage
          sx={{
            maxWidth: "100%",
            height: "100%",
            maxHeight: index > 0 ? ["100%", "188px", "235px"] : "100%",
          }}
          image={image.asset.gatsbyImageData}
          height={600}
          aspectRatio={4 / 2}
          alt="Gallery Image"
          title="Gallery Image"
        />
      </div>
    )
  } else if (image.video) {
    return <LiteYouTubeEmbed id={image.video} />
  } else if (image.childImageSharp) {
    return (
      <GatsbyImage
        sx={{
          maxWidth: "fit-content",
          height: "auto",
        }}
        image={image.childImageSharp.gatsbyImageData}
        width={900}
        aspectRatio={4 / 2}
        alt="Gallery Image"
      />
    )
  } else if (image.MediaURL) {
    if (index == 0) {
      return (
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            img: {
              aspectRatio: "20 / 13",
              MaxWidth: "100%",
              objectFit: "cover",
              display: "block",
            },
          }}
        >
          <img src={image.MediaURL} />
        </div>
      )
    } else {
      return (
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            img: {
              aspectRatio: "20 / 13",
              maxWidth: "100%",
              objectFit: "cover",
              display: "block",
            },
          }}
        >
          <img src={image.MediaURL} />
        </div>
      )
    }
  } else {
    return null
  }
}
const ReturnMobileImage = ({ image }) => {
  if (image.asset) {
    return (
      <GatsbyImage
        sx={{
          maxWidth: "100%",
          height: "auto",
          // maxHeight: "60vh",
        }}
        image={image.asset.gatsbyImageData}
        width={600}
        // aspectRatio={4 / 2}
        alt="Mobile Gallery Image"
      />
    )
  } else if (image.video) {
    return <LiteYouTubeEmbed id={image.video} />
  } else if (image.MediaURL) {
    return (
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          img: {
            aspectRatio: "20 / 13",
            maxWidth: "100%",
            objectFit: "cover",
            display: "block",
          },
        }}
      >
        <img src={image.MediaURL} />
      </div>
    )
  } else if (image.childImageSharp) {
    return (
      <GatsbyImage
        sx={{
          maxWidth: "100%",
          height: "auto",
        }}
        image={image.childImageSharp.gatsbyImageData}
        aspectRatio={4 / 2}
        alt="Mobile Gallery Image"
      />
    )
  } else {
    return null
  }
}
const PropImages = ({ newImages, openModal }) => {
  const breakpoints = useBreakpoint()
  return (
    <div
      sx={{
        ".indicators": {
          margin: "0px",
          padding: "0px 20px",
        },
      }}
    >
      <MenuTopBlack />

      {breakpoints.sm ? (
        <div>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="prop-menu"
              sx={{ background: "white", maxWidth: "326px", width: "100%" }}
            >
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
          <Slide {...properties}>
            {newImages.map((image, index) => (
              <div
                sx={{
                  cursor: "pointer",
                  height: "100%",
                }}
                onClick={() => openModal(index)}
                key={index}
              >
                <ReturnMobileImage image={image} />
              </div>
            ))}
          </Slide>
        </div>
      ) : (
        <div
          sx={{
            overflowY: "auto",
            height: ["auto", "100%", "100%"],
            boxSizing: "border-box",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            "> div": {
              width: "calc(50% - 2.5px)",
              marginBottom: "5px",
              "&:nth-child(1)": {
                width: "100%",
                maxHeight: "100%",
              },
              ".yt-lite": {
                width: "100%",
                pointerEvents: "none",
              },
            },
          }}
        >
          {newImages.map((image, index) => (
            <div
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => openModal(index)}
              key={index}
            >
              <ReturnImage image={image} index={index} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default PropImages
