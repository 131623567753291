/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Modal from "react-modal"
import Seo from "../components/seo"
import ConditionalLayout from "../components/ConditionalLayout"

import { truncate } from "../utils/stringUtils"
import GalleryModal from "./sections/GalleryModal"
import PropertyContainer from "./sections/PropertyContainer"
import PageContainer from "./sections/PageContainer"

const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return children
}

function youtube_parser(url) {
  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  var match = url.match(regExp)
  return match && match[1].length === 11 ? match[1] : false
}

class Property extends React.Component {
  constructor(props) {
    console.log(props)

    super(props)
    this.state = {
      modalIsOpen: false,
      slideIndex: "1",
      videoId: null,
      images: null,
      isHomepage: false,
      isModalProperty: false,
    }
    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
  }

  componentWillMount() {
    const search = typeof window !== "undefined" ? window.location.search : ""
    const arr = search.split("=")

    if (arr.length > 1 && arr[1] === "true") {
      const parent = document.getElementById("modal-parent")
      if (parent) {
        this.setState({
          isHomepage: false,
          isModalProperty: true,
        })
      } else {
        this.setState({
          isHomepage: false,
          isModalProperty: true,
        })
      }
    } else {
      this.setState({
        isHomepage: false,
        isModalProperty: false,
      })
    }
  }

  componentDidMount() {
    const modalPortals = document.getElementsByClassName("ReactModalPortal")
    const parent = document.getElementById("modal-parent")
    if (modalPortals[0] && parent) {
      parent.appendChild(modalPortals[0])
    }
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  closeModal() {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
    }))
  }
  openModal(index, videoId) {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
    }))
    this.setState({
      slideIndex: index,
    })
    this.setState({
      videoId: videoId,
    })
  }

  render() {
    let node,
      images,
      county,
      feedDescription,
      acreage,
      newImages,
      metaTitle,
      title,
      metaDescription,
      propPath,
      office,
      ourDisclaimer,
      description,
      contacts,
      status,
      disclaimer,
      OriginatingSystemName,
      ListOfficeName

    const host = this.props.data.site.siteMetadata.siteUrl
    if (this.props.data.property) {
      node = this.props.data.property

      images = node.Media
      county = node.county
      feedDescription = node.propertyDescription
      description = node.propertyDescription
      acreage = node.acreage
      newImages = images.slice()
      metaTitle = node.propertyName ? node.propertyName : node.mlsid
      title = node.propertyName ? node.propertyName : node.mlsid
      metaDescription = truncate(node.propertyDescription, 380)
      propPath = host + this.props.path
      office = this.props.data.property.field_office1
      status = node.status
      OriginatingSystemName = node.OriginatingSystemName
        ? node.OriginatingSystemName
        : null
      ListOfficeName = node.ListOfficeName ? node.ListOfficeName : null
      if (this.props.data.property.feed === "serbo") {
        disclaimer =
          "©2019 San Antonio Board of Realtors. All rights reserved. Information Deemed Reliable but Not Guaranteed. Information on this site is provided exclusively for consumers personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Listing courtesy of " +
          office +
          "."
      }
      if (this.props.data.property.feed === "mst") {
        disclaimer =
          "©2019 Kerrville Board of Realtors® All rights reserved. The data relating to real estate for sale on this web site comes in part from the Kerrville Board of Realtors®. The broker providing this data believes it to be correct, but advises interested parties to confirm the data before relying on it in a purchase decision. Some properties which appear for sale on this web site may subsequently have sold and may no longer be available. Listing courtesy of " +
          office +
          "."
      }
      if (this.props.data.property.feed === "idx") {
        disclaimer =
          "The data relating to real estate for sale on this website comes in part from the Internet Data Exchange (IDX) of the Central Hill Country Board of REALTORS® Multiple Listing Service (CHCBRMLS). The CHCBR IDX logo indicates listings of other real estate firms that are identified in the detailed listing information. The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information herein is deemed reliable but not guaranteed, representations are approximate, individual verifications are recommended. Copyright 2019 Central Hill Country Board of REALTORS®. All rights reserved. Listing courtesy of " +
          office +
          "."
      }
    } else {
      ourDisclaimer = this.props.data.disclaimer.nodes[0]._rawBlockcontent
      node = this.props.data.ourproperty
      images = node.propertyImages
      county = node.ourcounty
      description = node._rawPropertyDescrition
      contacts = node.propertyContacts
      acreage = node.acreage
      metaTitle = node.metaTitle ? node.metaTitle : node.propertyName
      propPath = host + this.props.path
      status = node.status
      title = node.propertyName

      if (status === "sold") {
        newImages = images.slice(0, 3)
      } else {
        newImages = images.slice()
      }

      if (node.metaDescription) {
        metaDescription = node.metaDescription
      } else {
        metaDescription = truncate(node.propertySummary, 380)
      }

      if (node.youtubeUrl) {
        var videoId = youtube_parser(node.youtubeUrl)
      }
    }

    let image = ""

    if (node.propertyImages && node.propertyImages.length > 0) {
      image = node.propertyImages[0].asset.gatsbyImageData.images.fallback.src
    }

    if (node.imageFile && node.imageFile[0] !== null) {
      if (node.imageFile[0].childImageSharp !== null) {
        image =
          node.imageFile[0].childImageSharp.gatsbyImageData.images.fallback.src
      }
    }

    if (this.state.isModalProperty && !this.state.isHomepage) {
      return (
        <div className="modal-prop">
          <Seo
            title={metaTitle}
            description={metaDescription}
            path={propPath}
            canonical={propPath}
            image={image}
          />
          <ClientOnly>
            <PageContainer node={this.props.data.page} />
            <Modal isOpen={true} onRequestClose={() => console.log("close")}>
              <ConditionalLayout background="#686A6E" data={this.props.data}>
                <GalleryModal
                  open={this.state.modalIsOpen}
                  closeModal={this.closeModal}
                  title={metaTitle}
                  images={newImages}
                  slideIndex={this.state.slideIndex}
                />
                <PropertyContainer
                  images={newImages}
                  openModal={this.openModal}
                  facebookPublicURL={this.props.data.facebook.publicURL}
                  twitterPublicURL={this.props.data.twitter.publicURL}
                  instagramPublicURL={this.props.data.instagram.publicURL}
                  carrotLeftPublicURL={this.props.data.carrotLeft.publicURL}
                  carrotDownPublicURL={this.props.data.carrotDown.publicURL}
                  contacts={contacts}
                  node={node}
                  title={title}
                  county={county}
                  acreage={acreage}
                  description={description}
                  feedDescription={feedDescription}
                  ourDisclaimer={ourDisclaimer}
                  disclaimer={disclaimer}
                  propPath={propPath}
                  status={status}
                  videoId={videoId}
                  propertyInfo={this.props.data._rawPropertyInfo}
                  logo={false}
                  OriginatingSystemName={OriginatingSystemName}
                  ListOfficeName={ListOfficeName}
                />
              </ConditionalLayout>
            </Modal>
          </ClientOnly>
        </div>
      )
    }

    return (
      <div
        className="no-modal-prop"
        sx={{
          "#modal-disclaimer": {
            display: "none",
          },
        }}
      >
        <Seo
          title={metaTitle}
          description={metaDescription}
          path={propPath}
          canonical={propPath}
          image={image}
        />
        <ClientOnly>
          <ConditionalLayout background="#686A6E" data={this.props.data}>
            <GalleryModal
              open={this.state.modalIsOpen}
              closeModal={this.closeModal}
              title={metaTitle}
              images={newImages}
              slideIndex={this.state.slideIndex}
            />
            <PropertyContainer
              images={newImages}
              openModal={this.openModal}
              facebookPublicURL={this.props.data.facebook.publicURL}
              twitterPublicURL={this.props.data.twitter.publicURL}
              instagramPublicURL={this.props.data.instagram.publicURL}
              carrotLeftPublicURL={this.props.data.carrotLeft.publicURL}
              carrotDownPublicURL={this.props.data.carrotDown.publicURL}
              contacts={contacts}
              node={node}
              title={title}
              county={county}
              acreage={acreage}
              description={description}
              feedDescription={feedDescription}
              ourDisclaimer={ourDisclaimer}
              disclaimer={disclaimer}
              propPath={propPath}
              status={status}
              videoId={videoId}
              propertyInfo={this.props.data._rawPropertyInfo}
              logo={true}
              OriginatingSystemName={OriginatingSystemName}
              ListOfficeName={ListOfficeName}
            />
          </ConditionalLayout>
        </ClientOnly>
      </div>
    )
  }
}
export default Property

export const postQuery = graphql`
  query PropertyBySlug($mlsid: String!) {
    property: property(ListingId: { eq: $mlsid }) {
      ...propertyFullFragment
    }
    ourproperty: sanityProperty(slug: { current: { eq: $mlsid } }) {
      ...ourPropertyFullFragment
    }
    blockFragment: sanityPageDefinition(slug: { current: { eq: "home" } }) {
      ...blockFragment
    }
    disclaimer: allSanityBlockcontent(
      filter: { blockId: { eq: "disclaimer" } }
    ) {
      nodes {
        blockId
        _rawBlockcontent(resolveReferences: { maxDepth: 10 })
      }
    }
    facebook: file(name: { eq: "dark-facebook" }) {
      name
      publicURL
    }
    twitter: file(name: { eq: "dark-twitter" }) {
      name
      publicURL
    }
    instagram: file(name: { eq: "dark-instagram" }) {
      name
      publicURL
    }
    youtube: file(name: { eq: "youtubenew" }) {
      name
      publicURL
    }
    linkedin: file(name: { eq: "LinkedINSVG" }) {
      name
      publicURL
    }
    phone: file(name: { eq: "PhoneSVG" }) {
      name
      publicURL
    }
    carrotLeft: file(name: { eq: "carrotLeft" }) {
      publicURL
    }
    carrotDown: file(name: { eq: "carrotDown" }) {
      publicURL
    }
    page: sanityPageDefinition(slug: { current: { eq: "home" } }) {
      slug {
        current
      }
      metaTitle
      metaDescription
      title
      _rawHero(resolveReferences: { maxDepth: 10 })
      _rawEntities(resolveReferences: { maxDepth: 10 })
    }
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`
const PropertyExperimentForUsingHooks = ({ location }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [istHomePage, setIsHomePage] = useState(false)
  const [isModalProperty, setIsModalProperty] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get("isModal")) {
      const parent = document.getElementById("modal-parent")
    } else {
    }
  }, [])
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div>
      <h1>Propiedad {modalIsOpen ? "Yes" : "No"}</h1>
      <div>{location.search}</div>
    </div>
  )
}
